import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Project} from "../../types/model";
import {ConfigData, WcLogChipping} from "../../types/data-types-woodchipping";
import {initialConfigData} from "../../types/initialize-types";
import {GetCurrentLoginState} from "../../services/service-auth";
import {buildComboVehicle, FetchDataConfig, GetDimensions, goTo} from "../../services/service-global";
import ButtonList from "../../compoents/common/ButtonList";
import InfoBarSelectedProject from "../../compoents/common/InfoBarSelectedProject";
import ListKeyVal from "../../compoents/common/ListKeyVal";
import Colors from "../../constants/Colors";
import BoxSelectAutoComplete from "../../compoents/common/BoxSelectAutoComplete";
import DateLabelForm from "../../compoents/common/DateLabelForm";
import InputLabelForm from "../../compoents/common/InputLabelForm";
import Page from "../Page";
import TopBar2 from "../../compoents/common/TopBar2";
import NavBarBackCancel from "../../compoents/common/NavBarBackCancel";
import styled from "styled-components";
import moment from "moment/moment";
import {AppName, DbName} from "../../constants/common";
import {PostToPMIS} from "../../services/service-http-post";


interface Props{

}
const {width, height} = GetDimensions()
export default (props:Props)=>{
    const state = useSelector((state: any) => state.core);
    const [Step, setStep] = useState(1)
    const [DataProject, setDataProject] = useState<Project[]>([])
    const [DataConfig, setDataConfig] = useState<ConfigData>(initialConfigData)
    const [SelectedProject, setSelectedProject] = useState("General Cleaning Services at City Buildings (Leased and Owned)")
    const [IsSync, setIsSync] = useState(false)
    const [LoginState, setLoginState] = useState(GetCurrentLoginState())
    const [InputFileName, setInputFileName] = useState("")
    const [InputBase64, setInputBase64] = useState("")
    const [InputClient,setInputClient]=useState("")

    let targetWidth = width
    if (width > 7) {
        targetWidth = 768
    }

    useEffect(() => {
        if (!IsSync) {
            loadDataConfig().then(null)
            setIsSync(true)
        }
    })

    const loadDataConfig = async () => {
        await FetchDataConfig(LoginState.Org, LoginState.Username, (res: any) => {
            console.log("loadDataConfig result> ", res, " !!=> ", state.LoginState)
            let data = res as ConfigData
            setDataConfig(data)
        })
    }
    const onClickContinue = () => {
        if (validationInput()) {
            setStep(4)
        } else {
            return
        }
        /**
         * todo validation inputs
         */
    }
    const validationInput = (): boolean => {
        const alertMe = (inputField: string) => {
            alert(`Input validation fail, ${inputField} is required!`)
        }
        if (InputFileName === "") {
            alertMe("File")
            return false
        } else if (InputBase64=== "") {
            alertMe("File content")
            return false
        } else if (InputClient=== "") {
            alertMe("Client")
            return false
        } else if (SelectedProject === "") {
            alertMe("Project ")
            return false
        }
        return true
    }
    const onBack = () => {
        if (Step > 1) {
            let step = Step - 1
            setStep(step)
        }else if(Step===1){
            goTo("/home")
        }
    }
    const onCancel = () => {
        if (!window.confirm("Are you sure to cancel this new chipping?")) {
            return
        }
        goTo("home")
    }
    const onSubmit = async () => {

        let endpoint = "/data/import/csv/chipping"
        let postData = {
            Org:LoginState.Org,
            Client:InputClient,
            Project:SelectedProject,
            FileName:InputFileName,
            Base64String:InputBase64,
        }
        console.log("Submit ):(--> send> ",postData)
        await PostToPMIS(postData,endpoint,(res:any)=>{
            console.log("Submit ):(--> response> ",res)
            alert("Thank you , csv log chipping submitted")
            goTo("/home")
        })
    }
    const onSelectClient = (pro: string) => {
        setInputClient(pro)
        setStep(2)
        console.log("::::onSelectClient > ", pro)
    }
    const onSelectProject = (pro: string) => {
        setSelectedProject(pro)
        setStep(3)
        console.log("::::onSelectProject > ", pro)
    }

    const getSummaryKeyValue = (): { key: string, value: any }[] => {
        return [
            {
                key: "Client",
                value: InputClient
            },
            {
                key: "Project",
                value: SelectedProject
            },
            {
                key: "File name",
                value: InputFileName
            },
        ]
    }
    const onDropHandler=() =>{
        let inputUpload = document.getElementById("fileID");
        // @ts-ignore
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            setInputFileName( myfile.name);
            // @ts-ignore
            setInputBase64( reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const getProjectList = (): string[] => {
        let ls: string[] = [];
        for (let i in DataConfig.Projects) {
            let row = DataConfig.Projects[i]
            if (row.Org !== LoginState.Org) {
                continue
            }
            if(row.Client!==InputClient){
                continue
            }
            ls.push(row.Name)
        }
        return ls
    }
    const getClientList=():string[]=>{
        let ls: string[] = [];
        for (let i in DataConfig.Projects) {
            let row = DataConfig.Projects[i]
            if (row.Org === LoginState.Org) {
                ls.push(row.Name)
            }
        }
        return ls
    }

    const RenderStep4 = () => {
        return (
            <>
                <Label2>
                    Following are your input summary provided,
                    please make sure that it is correct before continue.
                </Label2>
                <InfoBarSelectedProject project={SelectedProject}/>
                <ListKeyVal list={getSummaryKeyValue()}/>

                <MyContainer width={targetWidth - 60}>
                    <MyButton
                        width={(targetWidth - 60)}
                        bgColor={Colors.brand.green}
                        labelColor={Colors.brand.white}
                        onClick={onSubmit}
                    >Submit</MyButton>
                </MyContainer>

            </>
        )
    }
    const RenderStep3 = () => {
        return (
            <>
                <Label2>
                    Input your log information based on your delivery note.
                </Label2>
                <InfoBarSelectedProject project={SelectedProject}/>

                <MyContainer width={targetWidth - 60}>
                    <ContainerInputFile width={targetWidth-60} type={"file"} id={"fileID"} onChange={()=>onDropHandler()}/>
                </MyContainer>
                <MyContainer width={targetWidth - 60}>
                    <MyButton
                        width={(targetWidth) - 60}
                        bgColor={Colors.brand.gray}
                        labelColor={Colors.brand.white}
                        onClick={onClickContinue}
                    >Continue</MyButton>
                </MyContainer>
            </>
        )
    }
    const RenderStep2 = () => {
        return (
            <>
                <Label2>
                    Please select your target project from the list below,
                    and continue to input form
                </Label2>

                <ButtonList
                    options={getProjectList()}
                    onPress={onSelectProject}
                    selectedProject={SelectedProject}
                />
            </>
        )
    }
    const RenderStep1= () => {
        return (
            <>
                <Label2>
                    Please select your client from the list below,
                    and continue to input form
                </Label2>

                <ButtonList
                    options={getClientList()}
                    onPress={onSelectClient}
                    selectedProject={InputClient}
                />
            </>
        )
    }

    const SwitchView = () => {
        switch (Step) {
            case 1:
                return RenderStep1()
            case 2:
                return RenderStep2()
            case 3:
                return RenderStep3()
            case 4:
                return RenderStep4()
            default:
                return RenderStep1()
        }
    }

    return (
        <Page noMenu={false}>
            <TopBar2 title={"New Log CSV Chipping"}/>
            <Container>
                <NavBarBackCancel
                    onBack={onBack}
                    onCancel={onCancel}
                />
                <Label1>
                    Step: {Step}/{3}
                </Label1>
                {SwitchView()}
            </Container>
        </Page>
    )
}

const ContainerInputFile=styled.input<{width:number}>`
    min-width: ${(props)=>props.width}px;
  height: 35px;
  margin-top: 40px;
`
const InnerBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

const MyContainer = styled.div<{ width: number }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  min-height: 55px;
  padding: 5px;

`
const MyButton = styled.button<{ width: number, bgColor: string, labelColor: string }>`
  width: ${(props) => props.width}px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.labelColor};
  border: 1px solid white;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
`

const Label2 = styled.label`
  font-size: 22px;
  margin-top: 10px;
  text-align: left;
  font-weight: bold;
  width: 100%;
`
const Label1 = styled.label`
  font-size: 22px;
  font-weight: bold;
  color: ${Colors.brand.blue};
  margin-top: 10px;
  align-self: flex-start;
`
const Container = styled.div`
  background-color: ${Colors.brand.white};
  width: ${width - 40}px;
  min-height: ${(props) => height + "px"};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 768px;
  overflow-y: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
`