
import {Button, Col, DatePicker, Row} from "antd";
import React, {useEffect} from "react"
// @ts-ignore
import moment from "moment";
import {useState} from "react";
import {useSelector} from "react-redux";
import {OptionEntry, VehicleType} from "../../../types/general-types";
import Colors from "../../../constants/Colors";

import {FormatMoneySouthAfrica2} from "../../../services/formating-number";
import styled from "styled-components";
import BoxSelector from "./BoxSelector";
import InputButton from "./InputButton";
import InputBox from "./InputBox";

type Props = {
    onChangeDate: any,
    onChangeVehicle: any,
    onChangeDeliveryNote: any,
    onChangeUnits: any,
    onSubmit: any,
    setStep: any,
    DataVehicle: VehicleType[],
    SelectedVehicle:string
    SelectedSite:string,
    TargetPlannedSite:number,

    inputUnits:any,
    inputDeliveryNote:any,
}

export default (props: Props) => {

    const onCancel = () => {
        props.setStep("site")
    }
    const onChangeDate = (dateIn: string) => {
        console.log("On Change date: ", dateIn)
        props.onChangeDate(dateIn)
    }
    const getVehicleOption = (): OptionEntry[] => {
        let ls: OptionEntry[] = [];
        for (let i in props.DataVehicle) {
            let row = props.DataVehicle[i]
            ls.push({
                label: row.regnumber,
                key: row.regnumber
            })
        }
        return ls
    }
    return (

            <Container>

                <View style={{
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"space-between"
                }}>
                    <Text style={{color:Colors.brand.lightRed}}>Selected site: {props.SelectedSite}</Text>
                    <Text style={{color:Colors.brand.lightRed}}>Planned: {FormatMoneySouthAfrica2(props.TargetPlannedSite)}</Text>
                </View>

                <Box>
                    <Text> Log Date:</Text>
                    <DatePicker
                        style={{
                            height: 40,
                            width: "100%"
                        }}
                        onChange={(date, dateString) => onChangeDate(dateString as string)}
                        placeholder={"Log Date [ YYYY-MM-DD ]"}
                    />

                </Box>
                <SpaceTop>
                    <BoxSelector
                        label={"Vehicle"}
                        handleInput={(veh:any)=>props.onChangeVehicle(veh)}
                        data={getVehicleOption()}
                        SelectedValue={props.SelectedVehicle}
                    />
                </SpaceTop>
                <SpaceTop>
                    <InputBox
                        label={"Number of Units"}
                        handleInput={props.onChangeUnits}
                        required={true}
                        value={props.inputUnits}
                        styleIn={{}}
                    />
                </SpaceTop>
                <SpaceTop >
                    <InputBox
                        label={"Delivery note number"}
                        handleInput={props.onChangeDeliveryNote}
                        value={props.inputDeliveryNote}
                        styleIn={{}}
                        required={true}
                    />
                </SpaceTop>
                <SpaceTop>
                    <InputButton
                        label={"Submit"}
                        onClick={props.onSubmit}
                        styleIn={{
                            backgroundColor:Colors.brand.green,
                            width:"100%",
                            color:Colors.brand.white,
                        }}
                        color={Colors.brand.white}
                        type={"button"}
                        variant={"outlined"}
                    />
                </SpaceTop>
            </Container>

    )
}

const View = styled.div`
  width: 100%;
`
const Text = styled.label`
`

const SpaceTop = styled.div`
  margin-top:10px;
  width: 100%;
  
`
const Container = styled.div`
  padding:20px;
  width: 95%;
`
const Box = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  border-style: dotted;
  border-bottom-width: 1px;
`
const ContainerImage=styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Image = styled.image`
  width: 60px;
  height: 60px;
  margin-top: 20px;
  margin: 5px;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  border-radius: 30px;
`


