import styled from "styled-components";

interface Props {
    message: string
    color: string
    fontSize:number
}

export default (props: Props) => {
    console.log("Error Message Label > ",props);
    return (
        <Label color={props.color} fontSize={props.fontSize}>
            {props.message}
        </Label>
    )
}
const Label = styled.div<{
    color: string,
    fontSize: number,
}>`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};
`
